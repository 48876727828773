import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#deti"> &gt; Radca(DETI)</a>
            <a
              href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
              className="sel"
            >
              {" "}
              &gt; Imunita dieťaťa – dbajte a posilňujte
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_1668728821.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Imunita dieťaťa – dbajte a posilňujte</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    <b>
                      Imunitný systém je prirodzený štít, ktorý chráni
                      organizmus pred mikroorganizmami – mikróbmi vyvolávajúcimi
                      rôzne ochorenia. Imunitný systém sa buduje v prvých
                      dvanástich rokoch života. Práve v tejto vývinovej etape
                      preto treba zvyšovať obranyschopnosť organizmu. Existuje
                      na to niekoľko overených metód.{" "}
                    </b>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Úlohou imunitného systému je ochrana pred vírusmi, baktériami
                  a plesňami. Organizmus si mnoho rokov buduje bariéru, ktorá ho
                  chráni pred{" "}
                  <a href="/slovnik_pojmov/#infekcia" target="_blank">
                    infekciami
                  </a>
                  . Až dosiahnutie plnej imunologickej zrelosti zabezpečí väčšiu
                  odolnosť voči pôsobeniu{" "}
                  <a href="/slovnik_pojmov/#patogen" target="_blank">
                    patogénov
                  </a>
                  . V každom období vývinu je preto veľmi dôležité starať sa o
                  zdravie dieťaťa, poskytovať mu správnu stravu a zabezpečiť
                  vhodnú fyzickú aktivitu.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Novorodenca v prvých dňoch života chránia protilátky, ktoré mu
                  matka poskytovala počas tehotenstva. Túto rolu neskôr plní
                  materské mlieko, ktoré obsahuje{" "}
                  <a href="/slovnik_pojmov/#imunoglobuliny" target="_blank">
                    imunoglobulíny
                  </a>
                  , zabezpečujúce ochranu pred vírusmi. Aj dojčené deti –
                  pochopiteľne – ochorejú, stáva sa to však omnoho zriedkavejšie
                  a priebeh choroby je rozhodne miernejší. Stravu polročných
                  detí je potrebné obohatiť o zeleninu a ovocie a časom do nej
                  zaradiť aj chudé mäso, vajcia, jogurty a ryby, pretože
                  minerály a vitamíny sa najlepšie absorbujú práve z týchto
                  potravín. Spolu s nárastom telesnej hmotnosti a rozvojom
                  vnútorných orgánov sa treba postarať o správnu dávku živín
                  (denná spotreba energie a živín u dieťaťa vo veku 1 až 3 rokov
                  je približne 1000 – 1300 kcal, u dvanásťročného už 2300 – 2600
                  kcal). K najlepším kúram na posilnenie obranyschopnosti patria
                  vitamíny obsiahnuté v skonzumovanej strave:
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <ul>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    A (posilňuje imunitný systém organizmu, nachádza sa v mrkve,
                    tekvici, špenáte alebo paradajkách),
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    C (vytvára ochrannú bariéru pred škodlivými
                    mikroorganizmami, nachádza sa s v čiernych ríbezliach,
                    citrónoch, jablkách a kapustách rôznych druhov),
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    D (organizmu ho dodávame konzumáciou vajec, rýb a pitím
                    mlieka a mliečnych výrobkov),
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    DHA (kyselina zo skupiny omega-3, prítomná v rybách a
                    morských riasach),
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    E a B6 (pomáhajú v boji proti vírusom, nachádzajú sa v
                    orechoch, zrnách obilnín, šalátoch a špenáte).
                  </li>
                </ul>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Na celkové posilnenie obranyschopnosti organizmu je nevyhnutný
                  aj pohyb, napríklad každodenné hodinové prechádzky alebo hry
                  na čerstvom vzduchu. Dieťa musí byť oblečené vhodne podľa
                  počasia, najlepšie ako tzv. cibuľa (vo vrstvách). Nezabúdajme
                  na to, aby pri sedení v kočíku malo na sebe o jednu vrstvu
                  viac a pri fyzickej aktivite o jednu vrstvu menej ako mama.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Dôležitú úlohu pri posilňovaní organizmu zohráva aj spánok,
                  ktorý je potrebný na regeneráciu, vývin a rast. Ak má dieťa
                  upchatý nos (z dôvodu opuchu alebo zahlienenia), nedokáže
                  pokojne prespať noc. Spánok prerušovaný dýchacími ťažkosťami
                  oslabuje imunitný systém, ktorý ešte nie je plne vyvinutý, a
                  je príčinou podráždenia počas dňa. Aby sme tomu zabránili,
                  treba večer vyvetrať spálňu, zabezpečiť v miestnosti teplotu
                  18 – 19 stupňov a (v prípade nádchy) použiť liek na uvoľnenie
                  nosa a uľahčenie dýchania, napríklad{" "}
                  <a href="/lieky/olynth_ha_05/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,05 %
                  </a>
                  .
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Zoznámte sa s metódami posilňovanie detskej imunity."
                    src="/web/kcfinder/uploads/images/166756995(2).jpg"
                    style={{
                      width: "380px",
                      height: "245px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid #fff"
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Dieťaťu obzvlášť pri nádche hrozia mnohé komplikácie, napr.
                  zápal stredného ucha. Neliečené alebo ignorované infekcie
                  horných dýchacích ciest vytvárajú priaznivé podmienky pre
                  vznik zápalových stavov v oblasti ucha. Príčinou je hlien
                  nahromadený v nose, ktorý steká do dutín a{" "}
                  <a href="/slovnik_pojmov/#sluchova_trubica" target="_blank">
                    Eustachovej trubice
                  </a>
                  . U detí vedie sluchová trubica viac vodorovne ako u dospelých
                  a zostáva neustále otvorená, preto sa do nej omnoho rýchlejšie
                  dostanú nahromadené hlieny. Práve preto je dôležité okamžite
                  začať liečbu spočívajúcu v zmiernení opuchu sliznice, čím sa
                  nos uvoľní a hlieny sa odstránia.
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <em>Grypa i przeziębienie. Porady lekarza rodzinnego</em>,
                    opracowanie zbiorowe, Literat, 2012.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Iwanowicz-Palus Grażyna,{" "}
                    <em>Ciąża, poród, macierzyństwo</em>, Wydawnictwo Rea, 2013.
                  </span>
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Zielińska-Pisklak Monika, Szeleszczuk Łukasz, Kuras Marzena,{" "}
                    <em>
                      Rola witaminy C i cynku we wspomaganiu układu
                      odpornościowego
                    </em>
                    , [v:] „Lek w Polsce”, zv. 23, č. 11-12’13.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a href="/radca/deti/upchaty_nos_u_detC3AD/" className="box">
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png)"}} />
                  <span className="title">Upchatý nos u detí</span>
                </a>
                <a
                  href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/walka_infekcjami_katar-_kaszel-_dziecka.png)"}} />
                  <span className="title">
                    Boj s infekciami – nádcha a kašeľ u dieťaťa
                  </span>
                </a>
                <a href="/radca/deti/ako_naucit_dieta_siakat/" className="box">
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/dzieci_poradnik_03.jpg)"}} />
                  <span className="title">Ako naučiť dieťa siakať?</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
